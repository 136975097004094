<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      class="col-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Tambah Kategori</h2>
    </b-col>
    <b-col class="col-6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Kategori</label>
            <b-form-input
              v-model="categoryInventory.nama"
              type="text"
              :state="v$.categoryInventory.nama.$dirty ? !v$.categoryInventory.nama.$error : null"
              placeholder="Masukkan Nama Kategori"
            />
            <b-form-invalid-feedback :state="v$.categoryInventory.nama.$dirty ? !v$.categoryInventory.nama.$error : null">
              {{ errorMessages.nama }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Catatan</label>
            <b-form-textarea
              v-model="categoryInventory.catatan"
              placeholder="Masukkan Catatan"
              :rows="5"
            />
          </div>
        </li>
      </ul>
      <b-col class="d-flex justify-content-end mb-3">
        <b-button
          variant="danger-1 mx-2"
          @click="$router.go(-1)"
        >
          Batal
        </b-button>
        <b-button
          variant="success-1 "
          type="submit"
          :loading="isBusy"
          @click="storeCategory"
        >
          <b-spinner
            v-if="isBusy"
            small
            label="Small Spinner"
          />
          <span v-else>Simpan</span>
        </b-button>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import fieldMappings from './data/fieldMappings'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'StoreCategory',
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BSpinner,
  },
  data() {
    return {
      categoryInventory: {
        nama: '',
        catatan: '',
      },
      errorMessages: {},
    }
  },
  validations() {
    return {
      categoryInventory: {
        nama: { required, minLength: minLength(3) },
      },
    }
  },
  computed: {
    isBusy() {
      return this.$store.state.manageCategoryInventory.isBusy
    },
  },
  methods: {
    async storeCategory() {
      const formData = new FormData()

      formData.append('category_name', this.categoryInventory.nama)
      formData.append('description', this.categoryInventory.catatan)

      await this.$store.dispatch('manageCategoryInventory/storeCategory', formData)
        .then(() => {
          this.$router.push({ name: 'categoryinventorys' })

          this.$bvToast.toast('Data Kategori Berhasil Ditambahkan', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.success === false && err.response.data.message) {
            const errorMessage = err.response.data.message

            // Find the translated error message if available
            const translatedErrorMessage = Object.entries(fieldMappings).find(([key]) => errorMessage.includes(key))
            const finalErrorMessage = translatedErrorMessage ? translatedErrorMessage[1] : errorMessage

            Object.keys(this.categoryInventory).forEach(field => {
              if (this.v$.categoryInventory[field]) {
                this.v$.categoryInventory[field].$error = true
                this.v$.categoryInventory[field].$touch()
                this.$set(this.errorMessages, field, fieldMappings[field])

                this.$bvToast.toast(finalErrorMessage, {
                  title: 'Data Gagal Dikirim',
                  variant: 'danger',
                  solid: true,
                })
              }
            })
          }
        })
    },
  },
}
</script>
  <style>

  </style>
